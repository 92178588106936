<template>
  <div>
    <div class="container-600">
      <p class="camembert mb-8">
        Служебные пользователи
      </p>
      <r-input
        label="Поиск по ФИО, имейлу или телефону"
        v-model="searchText"
        @input="getUsers"
      >
        <r-icon
          :size="16"
          slot="before"
          icon="search"
          fill="titanic"
          class="opacity-48"
        />
      </r-input>
      <div class="flex align-center mt-8">
        <r-checkbox
          v-model="allEmails"
          title="Все имейлы"
          class="mr-6"
          @input="addAllEmails"
        />
        <r-checkbox
          v-model="allPhones"
          title="Все телефоны"
          @input="addAllPhones"
        />
        <sup class="ml-1">
          <r-tooltip
            activator-hover
            color="amelie"
            title=" Направится смс оповещение, при условии подключенного сервиса для СМС-рассылок"
            position="top-center"
            max-width="400px"
            :is-arrow="true"
          >
            <template #activator>
              <r-icon
                size="16"
                icon="help"
                fill="titanic"
                class="pointer opacity-48"
              />
            </template>
          </r-tooltip>
        </sup>
      </div>
      <div class="scroll_container">
        <div
          class="scroll_container__item flex align-start mt-8"
          v-for="item of users"
          :key="item.id"
        >
          <div class="image mr-2">
            <img
              :src="item.image"
              alt=""
              v-if="!!item.image"
            >
          </div>
          <div>
            <p class="bryndza">
              {{ item.firstName + " " + item.lastName }}
            </p>
            <p
              class="mozzarella opacity-48 text-overflow pb-2"
              v-if="item.organisations"
              v-text="item.organisations + (!!item.orgPosition ? ', ' + item.orgPosition : '')"
            />
            <r-checkbox
              class="mt-2"
              v-if="item.email"
              v-model="choicestEmails"
              :val="{id: item.id, emails: [item.email], username: `${item.firstName} ${item.lastName}` }"
              :title="item.email "
              @input="allEmails = false"
              :return-object="true"
            />
            <r-checkbox
              class="mt-2"
              v-if="item.phone"
              v-model="choicestPhones"
              :val="{id: item.id, phones: [item.phone], username: `${item.firstName} ${item.lastName}` }"
              :title="item.phone "
              @input="allPhones = false"
              :return-object="true"
            />
          </div>
        </div>
        <infinite-loading
          @infinite="infiniteHandler"
          ref="infinite"
        >
          <div slot="spinner">
            <div class="flex justify-content-center my-3">
              <r-spinner />
            </div>
          </div>
          <div slot="no-more" />
          <div slot="no-results">
            <span class="feta opacity-72 mb-1">К сожалению, ничего не найдено</span>
            <span class="mozzarella opacity-48">Попробуйте изменить запрос</span>
          </div>
        </infinite-loading>
      </div>
    </div>
    <div class="button_container">
      <div class="container-600">
        <r-button
          @click="submit"
          width="wide"
          title="Сохранить"
        />
        <p
          class="caprino opacity-48 mt-3"
        >
          Выбрано имейлов: {{ choicestEmails.length }}; телефонов: {{ choicestPhones.length }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import infiniteLoading from 'vue-infinite-loading';
import { mergeArrays, wordMatch } from '../helpers/utils';

export default {
  name: 'ModalContacts',
  components: {
    infiniteLoading
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    saveFunction: {
      type: Function,
      required: true
    },
    emails: {
      type: Array,
      default: () => []
    },
    phones: {
      type: Array,
      default: () => []
    }
  },
  data(vm) {
    return {
      searchText: '',
      choicestEmails: vm.$props.emails || [],
      choicestPhones: vm.$props.phones || [],
      allEmails: false,
      allPhones: false,
      users: [],
      page: 1,
      limit: 50
    };
  },
  computed: {
    filteredObjects() {
      const { items } = this.$store.getters.getActiveUsers;
      /* if (this.searchText && this.searchText.length > 0) {
        items = items.filter(
          el => wordMatch(el?.firstName || '', this.searchText) <= 0.5
            || wordMatch(el?.lastName || '', this.searchText) <= 0.5
            || wordMatch(el?.email || '', this.searchText) <= 0.5
            || wordMatch(el?.phone || '', this.searchText) <= 0.5
        );
      } */
      return items;
    }
  },
  methods: {
    infiniteHandler($state) {
      // this.$store.dispatch('loadObjects',{typeId: 0, page: this.page});
      this.$store.dispatch('getUsers', {
        token: window.token,
        query: this.searchText,
        limit: this.limit,
        skip: (this.page * this.limit) - this.limit
      }).then(res => {
        const objects = res.payload.content;
        if (objects.length) {
          this.page += 1;
          this.users.push(...objects);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    getUsers: _.debounce(function (e) {
      this.$store.dispatch('getUsers', {
        token: window.token,
        query: this.searchText,
        limit: this.limit,
        skip: 0
      }).then(res => {
        this.page = 1;
        this.users = res.payload.content;
      });
    }, 300),
    submit() {
      const recipients = [];
      _.merge(recipients, this.choicestEmails);
      _.merge(recipients, this.choicestPhones);
      /* this.choicestEmails.length > 0
        ? mergeArrays(this.choicestPhones, this.choicestEmails)
        : mergeArrays(this.choicestEmails, this.choicestPhones); */

      this.saveFunction({
        choicestEmails: this.choicestEmails,
        choicestPhones: this.choicestPhones,
        recipients
      });
      this.$rir.modal.close();
    },
    addAllEmails() {
      if (this.allEmails) {
        this.choicestEmails = this.$props.items.reduce((acc, el) => {
          if (el.email) {
            acc.push({ id: el.id, emails: [el.email], username: `${el.firstName} ${el.lastName}` });
          }
          return acc;
        }, []);
      } else {
        this.choicestEmails = [];
      }
    },
    addAllPhones() {
      if (this.allPhones) {
        this.choicestPhones = this.$props.items.reduce((acc, el) => {
          if (el.phone) {
            acc.push({ id: el.id, phones: [el.phone], username: `${el.firstName} ${el.lastName}` });
          }
          return acc;
        }, []);
      } else {
        this.choicestPhones = [];
      }
    }
  }
};
</script>

<style scoped lang="scss">
.container-600 {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 560px;
}

.button_container {
  padding: 24px 0px 16px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
  display: flex;
  justify-content: center;
  text-align: center;
}

.scroll_container {
  padding-bottom: 150px;

  &__item {
    .image {
      width: 40px;
      height: 40px;
      background-color: rgba(61, 117, 228, 0.08);
      flex-shrink: 0;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
